@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans 3", sans-serif;
}

body {
  background-color: #f8f8f8;
  color: #101828;
}

/* override antd style */
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: transparent;
  color: #6b6b6b;
  font-weight: 400;
  font-size: 16px;
  border-bottom-color: #d9d9d9;
  border-radius: 1px;
  border-bottom-width: 0.6px;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom-color: #d9d9d9;
  border-radius: 1px;
  border-bottom-width: 0.6px;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}

.ant-select-selector {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  border-color: rgb(229, 231, 235) !important;
  box-shadow: none !important;
  padding: 2px 4px !important;
  font-size: 14px !important;
}

.my-textarea {
  border-color: rgb(229, 231, 235) !important;
  border-radius: 8px !important;
}

.my-textarea:hover,
.my-textarea:focus,
.my-textarea:focus-within {
  box-shadow: none !important;
}


